<template>
  <CModalDev
    :show.sync="boo_modal"
    :closeOnBackdrop="true"
    :centered="true"
    title="Modal title 2"
    :size="size"
    color="dark"
  >
    <CRow>
      <CCol md="12" lg="12" xs="12" sm="12">
        <CTextarea label="Motivo" v-model="data.motive" placeholder="motivo del egreso"></CTextarea>
      </CCol>
      <CCol
        sm="12"
        xs="12"
        md="12"
        lg="12"
        class=""
      >
        <table class="table table-striped table-bordered responsive">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo pago</th>
              <th>Monto</th>
              <th>Transacción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in data.amount" :key="index">
              <td>{{index + 1}}</td>
              <td>{{item.text}}</td>
              <td>
                <input type="text" @blur="mtdOutPayment(index)" v-model="item.amount" placeholder="Monto" class="form-control form-control-sm">
              </td>
              <td>
                <input :disabled="!item.transaction" type="text" v-model="item.num_transaction" placeholder="Transacción" class="form-control form-control-sm">
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="text-right text-bold">TOTAL</th>
              <th>{{data.total}}</th>
            </tr>
          </tfoot>
        </table>
      </CCol>
    </CRow>
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="mtd_close" class="text-white" />
    </template>
    <template #footer>
      <CButton @click="mtd_close" color="danger">Cancelar</CButton>
      <CButton @click="mtd_commit" color="success">Aceptar</CButton>
    </template>
  </CModalDev>
</template>

<script>
import CModalDev from "../../components/shared/modals/cModalDev.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "c-modal-delete",
  components: { CModalDev, vSelect },
  props: {
    title: String,
    boo_modal: Boolean,
    data: {},
    size: String,
  },
  data() {
    return {
    };
  },
  methods: {
    mtd_close() {
      this.$emit("closeDeleteExpense");
    },
    mtd_commit() {
      this.$emit("mtdCommitExpense", this.data);
    },
    mtdOutPayment: function(){
      let total = 0;
      this.data.amount.forEach(element => {
        total += parseFloat(element.amount);
      });
      this.data.total = parseFloat(total).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>