<template>
  <div>
    <CRow>
      <div
        class="col-md-12 d-flex align-items-center justify-content-between mb-3 animate__animated animate__backInDown animate__slow"
      >
        <h6 class="font-weight-bold text-gray-900 mb-0">
          Fecha apertura: {{ bead != null?bead.date_start:'' }}
        </h6>
        <button
          class="btn btn-lg btn-danger d-flex align-items-center"
          type="button"
          @click="mtdCloseBead"
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="ml-2">CERRAR CAJA</span>
        </button>
      </div>
    </CRow>
    <CRow>
        <!-- Lista de Ingresos-->
        <CCol lg="6" md="12" sm="12" xs="12">
          <div class="animate__animated animate__backInLeft">
            <CCol sm="12" md="12">
              <CCard border-color="success" class="py-1">
                <CCardHeader
                  class="d-flex align-items-center justify-content-between"
                  style="text-align: center; font-size: 18px; font-weight: bold"
                >
                  INGRESOS
                  <CButton
                    type="button"
                    :class="btnClasses"
                    @click="mtdOpenModalIncomes"
                    ><i class="fas fa-plus"></i>&nbsp; Agregar
                  </CButton>
                  <!-- <CButton
                    type="button"
                    :class="btnClasses"
                    @click="mtd_open_modal_charge"
                    ><i class="fas fa-plus"></i>&nbsp; Cobrar
                  </CButton> -->
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtdViewdetIncomes(1)"
                        style="
                          font-size: 18.5px;
                          margin-top: 10px;
                          background: #26994d;
                          color: white;
                        "
                        >Total Efectivos: S/ {{ cpTotalIncomeCash }}</span
                      >
                    </CCol>
                    <CCol
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtdViewdetIncomes(2)"
                        style="
                          font-size: 18.5px;
                          margin-top: 10px;
                          background: #26994d;
                          color: white;
                        "
                        >Total Otros: S/
                        {{ cpTotalIncomeOther }}</span
                      >
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CCol>
        <!-- Lista de Egresos-->
        <CCol lg="6" md="12" sm="12" xs="12">
          <div class="animate__animated animate__backInRight">
            <CCol sm="12" md="12">
              <CCard border-color="warning" class="py-1">
                <CCardHeader
                  class="d-flex align-items-center justify-content-between"
                  style="text-align: center; font-size: 18px; font-weight: bold"
                >
                  EGRESOS
                  <CButton
                    type="button"
                    class="btn btn-warning"
                    @click="mtdOpenModalExpense"
                    ><i class="fas fa-plus"></i>&nbsp; Agregar
                  </CButton>
                  <!-- <CButton
                    type="button"
                    class="btn btn-warning"
                    @click="mtd_open_modal_pagos"
                    ><i class="fas fa-plus"></i>&nbsp; Pagos
                  </CButton> -->
                </CCardHeader>
                <CCardBody>
                  <CRow>
                    <CCol
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtdViewdetExpense(1)"
                        style="font-size: 18.5px; margin-top: 10px"
                        >Total Efectivos: S/
                        {{ cpTotalExpenseCash }}</span
                      >
                    </CCol>
                    <CCol
                      lg="6"
                      md="6"
                      sm="12"
                      xs="12"
                      class="col-md-6 pr-0 text-center"
                    >
                      <span
                        class="
                          pointer
                          badge badge-warning
                          animate__animated
                          animate__pulse
                          animate__slow
                          animate__infinite
                          py-2
                        "
                        @click="mtdViewdetExpense(2)"
                        style="font-size: 18.5px; margin-top: 10px"
                        >Total Otros: S/
                        {{ cpTotalExpenseDeposits }}</span
                      >
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          </div>
        </CCol>
        <!-- Lista de Resultdos-->
        <CCol lg="12" md="12" sm="12" xs="12">
          <div
            class="col-md-12 animate__animated animate__bounceInUp container"
          >
            <div class="row">
              <div class="col-md-12 d-flex justify-content-around flex-wrap">
                <div>
                  <span
                    class="badge badge-secondary px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Efectivo aperturado: S/
                    {{ parseFloat(bead.initial).toFixed(2) }}</span
                  >
                </div>
                <!-- <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Anticipos: S/. {{ cp_anticipos_total }}</span
                  >
                </div> -->
                <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Ingresos Efectivo: S/. {{ cpTotalIncomeCash }}</span
                  >
                </div>
                <div>
                  <span
                    class="badge badge-info px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Egresos Efectivo: S/. {{ cpTotalExpenseCash }}</span
                  >
                </div>
                <div>
                  <span
                    class="badge badge-dark px-3 py-2 m-2"
                    style="font-size: 18px"
                    >Efectivo Final: S/. {{ cpFinalBalance }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </CCol>
    </CRow>

    <!-- modal nuevo egreso -->
    <cModalExpenseVue
      title="Agregar egreso"
      :boo_modal="modalExpense.boo"
      :data="modalExpense.data"
      @closeDeleteExpense="closeDeleteExpense"
      :size="'lg'"
      @mtdCommitExpense="mtdCommitExpense"
    />

  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalExpenseVue from '../../components/bead/cModalExpense.vue';
import { bus } from "../../main";


export default {
  name: "v-bead-index",
  components:{cModalExpenseVue},
  data() {
    return {
      bead: null,
      tot_incomes_cash:0.00,
      tot_incomes_deposits:0.00,
      tot_expenses_cash:0.00,
      tot_expenses_deposits:0.00,

      /** modales */
      modalExpense: {
        boo: false,
        data: {
          motive:"",
          amount:[
            {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
            {text:'Yape',amount:0,transaction:true, num_transaction:""},
            {text:'Plin',amount:0,transaction:true, num_transaction:""},
            {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
            {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
          ],
          total:"",
        },
      },
    };
  },
  computed: {
    /** cerrar */
    btnClasses() {
      return [`btn btn-${this.color || "success"}`];
    },
    cpTotalIncomeCash: function () {
      let total_incomes_cash = 0.0;
      if (this.bead) {
        this.bead.transaction.forEach(element => {
          if (element.type == 1) {
            let amountJson = JSON.parse(element.transaction);
            total_incomes_cash += parseFloat(amountJson[0].amount) 
          }
        });
        this.tot_incomes_cash = parseFloat(total_incomes_cash).toFixed(2);
      }
      return this.tot_incomes_cash;
    },
    cpTotalIncomeOther: function () {
      let total_incomes_other = 0.0;
        if (this.bead) {
        this.bead.transaction.forEach(element => {
          if (element.type == 1) {
            let amountJson = JSON.parse(element.transaction);
            amountJson.forEach(ele => {
                total_incomes_other  += parseFloat(ele.amount);
            });
          }
        });
        this.tot_incomes_deposits = parseFloat(total_incomes_other - this.tot_incomes_cash).toFixed(2);
      }

      return this.tot_incomes_deposits;
    },
    cpTotalExpenseCash: function () {
      let total_expe_cash = 0.0;
       if (this.bead) {
        this.bead.transaction.forEach(element => {
          if (element.type == 2) {
            let amountJson = JSON.parse(element.transaction);
            total_expe_cash += parseFloat(amountJson.amount[0].amount) 
          }
        });
        this.tot_expenses_cash = parseFloat(total_expe_cash).toFixed(2);
      }
      return this.tot_expenses_cash;
    },
    cpTotalExpenseDeposits: function () {
      let total_expe_deposits = 0.0;
      if (this.bead) {
        this.bead.transaction.forEach(element => {
          if (element.type == 2) {
            let amountJson = JSON.parse(element.transaction);
            amountJson.amount.forEach(ele => {
              total_expe_deposits += parseFloat(ele.amount);
            });
          }
        });
        this.tot_expenses_deposits = parseFloat(total_expe_deposits-this.tot_expenses_cash).toFixed(2);
      }
      return this.tot_expenses_deposits;
    },
    cpFinalBalance: function () {
      //* Inicial + Total Ingresos Efectivo + Pago de Cuota - Total Egresos Efectivo
      if (this.bead) {
       let final =
          parseFloat(this.bead.initial) + //! Inicial
          parseFloat(this.tot_incomes_cash)  -//! Total Ingresos Efectivo
          parseFloat(this.tot_expenses_cash); //! Total de Egresos Efectivo
        this.final_balance = parseFloat(final).toFixed(2);
        this.bead.final_balance = this.final_balance;
        return this.final_balance; 
      }
      return 0.00;
    },
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/bead",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.bead = response;
        })
        .catch((errors) => {});
    },
    mtdCloseBead: function(){

    },
    mtdOpenModalIncomes: function(){

    },
    mtdViewdetIncomes: function(type){

    },
    /** egresos */
    mtdOpenModalExpense: function(){
       this.modalExpense.boo = true;
       this.modalExpense.data={
        motive:"",
        amount:[
          {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
          {text:'Yape',amount:0,transaction:true, num_transaction:""},
          {text:'Plin',amount:0,transaction:true, num_transaction:""},
          {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
          {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
        ],
        total:"",
       };
    },
    closeDeleteExpense: function(){
      this.modalExpense={
        boo: false,
        data: {
          motive:"",
          amount:[
            {text:'Efectivo',amount:0,transaction:false, num_transaction:""},
            {text:'Yape',amount:0,transaction:true, num_transaction:""},
            {text:'Plin',amount:0,transaction:true, num_transaction:""},
            {text:'Transferencia',amount:0,transaction:true, num_transaction:""},
            {text:'Tarjeta',amount:0,transaction:true, num_transaction:""},
          ],
          total:"",
        },
      };
    },
    mtdCommitExpense: function(payload){
      this.post({
        url: this.$store.getters.get__url + "/bead/store/expense/"+this.bead.id,
        token: this.$store.getters.get__token,
        params:{data:payload}
      })
        .then((response) => {
          console.log(response);
        })
        .catch((errors) => {});
    },
    mtdViewdetExpense: function(){

    }
  },
};
</script>

<style lang="scss" scoped></style>
